<template>
  <div id="body">
    <Map />
  </div>
</template>

<script>
import Map from './components/Map';

export default {
  name: '',
  data() {
    return {};
  },
  methods: {},
  components: {
    Map,
  },
};
</script>

<style scoped lang="less">
#body {
  display: flex;
}
</style>
